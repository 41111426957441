
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import sedes from "@/services/sedes.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "editar-area",
  components: {},
  setup() {
    const ubicaciones = ref([]);
    const ubicacionId = ref("");
    const nombre = ref("");
    const route = useRoute();
    const router = useRouter();
    const infoArea = ref({});
    const traerInfoAreas = () => {
      sedes.traerAreaPorId(route.params.id).then((res) => {
        console.log("Info Area", res.data);
        nombre.value = res.data.nombre;
        ubicacionId.value = res.data.sedeId;
      });
    };
    const submit = () => {
      sedes
        .editarAreas(route.params.id, {
          nombre: nombre.value,
          sedeId: ubicacionId.value,
        })
        .then((res) => {
          console.log(res);
          Swal.fire(
            "Mensaje",
            "La nueva area se ah editado con exito",
            "success"
          )
            .then((res) => {
              console.log(res);
              router.back();
            })
            .catch((err) => {
              console.log(err.response.data.message);
              Swal.fire("Error", err.response.data.message, "error");
            });
        });
    };
    const traerUbicaciones = () => {
      sedes.listarSedes().then((res) => {
        console.log(res);
        ubicaciones.value = res.data;
      });
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Crear área", ["Pages", "Equipos"]);
      traerUbicaciones();
      traerInfoAreas();
    });
    return { ubicaciones, submit, nombre, ubicacionId, infoArea };
  },
});
